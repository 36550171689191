:root {
    --primary: #000;
    --secondary: #7acadb;
    --tertiary: #5ca7b8;
    --highlight: #2a5175;
    --light: #717171;
    --lightgrey: #d3d3d3;

    --error: #c31111;
    --ready : #e5a926;
    --success: #2caf3f;

    --sidemenuwidth : 280px;
}

html, body, * {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    background-color: var(--secondary);
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

#products_table_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
    background-color: var(--highlight);
}

.container {
    max-width: 1400px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    gap : 20px;
}

.col-3 {
    width: calc(33.33% - 20px);
}
.col-4 {
    width: calc(25% - 20px);
}
.col-9 {
    width: calc(66.67% - 20px);
}

h4 {
    margin-bottom:0;
}

.main {
    display: grid;
    grid-template-columns: var(--sidemenuwidth) calc(100% - var(--sidemenuwidth));
}

section {
    min-height: 100vh;
    padding: 20px 40px 100px;
}

.menu {
    display: flex;
    flex-direction: column;
}

#sidemenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    position: relative;
    z-index: 100;
}

#sidemenu > div {
    background-color: #efefef;
    position: fixed;
    /*box-shadow: 1px 1px 10px #ccc;*/
    height: 100%;
    padding: 15px;
    left: 0;
    top: 0;
    width: var(--sidemenuwidth);
    transition: all 300ms;
}

h1, h1 span {
    margin-top: 50px;
    text-transform: uppercase;
    text-align: center;
}

a{
    color: var(--highlight);
}

.clickable:hover {
    cursor: pointer;
    color: var(--highlight);
}

img {
    max-width: 100%;
}

.statistic-table-link {
    font-weight: bold;
}


#logo {
    width: 100px;
}

.logocontainer {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
}

.loggedin {
    align-items: center;
    background-color: var(--highlight);
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    position: fixed;
    padding-right: 10px;
    gap: 15px;
    left: 0;
    top: 0;
    height: 30px;
    width: 100%;
    z-index: 5;
}

.loggedin a {
    color: #fff;
}
.loggedin i {
    margin-right: 5px;;
}
.loggedin div {
    color: #fff;
    padding: 4px 5px;
}
.loggedin div:hover,
.loggedin a:hover {
    color: var(--secondary);
    cursor: pointer;
}

.table-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    
}



.table-buttons {
    background: var(--highlight);
    padding: 0px 25px 20px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
}

.table-buttons.top {
    justify-content: flex-end;
    border-top: none;
    gap: 10px;
    /*margin-bottom: 20px;*/
}

.table-buttons.detail {
    /*position: fixed;
    width: calc(100vw - 400px);
    bottom: 50px;*/
}

.table-link {
    color: var(--highlight);
    margin-right: 10px;
    text-decoration: none;
}
.table-link:hover {
    color: var(--secondary);
}

/* ------------------- LOADOING ------------- */

.loading {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: fixed;
    top: 0; 
    left:0;
    width: 100vw;
    z-index: 1000;
}

#circles {
    position: relative;
    margin: 40vh auto;
    width: 50px;
    height:50px;
}

#circles .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

#circles .circle:before {
    content: '';
    display: block;
    /* kreis mit steemit Hintergrundfarbe */
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    animation: lebe 1s infinite linear;
  }
  #circles .circle0{
    transform: rotate(36deg);
  }
  #circles .circle1 {
    transform: rotate(72deg);
  }
  #circles .circle2 {
    transform: rotate(108deg);
  }
  #circles .circle3 {
    transform: rotate(144deg);
  }
  #circles .circle4 {
    transform: rotate(180deg);
  }
  #circles .circle5 {
    transform: rotate(216deg);
  }
  #circles .circle6 {
    transform: rotate(252deg);
  }
  #circles .circle7 {
    transform: rotate(288deg);
  }
  #circles .circle8 {
    transform: rotate(324deg);
   }
   #circles .circle9 {
    transform: rotate(360deg);
  }
  #circles .circle1:before {
    animation-delay: -0.9s;
  }
  #circles .circle2:before {
    animation-delay: -0.8s;
  }
  #circles .circle3:before {
    animation-delay: -0.7s;
  }
  #circles .circle4:before {
    animation-delay: -0.6s;
  }
  #circles .circle5:before {
    animation-delay: -0.5s;
  }
  #circles .circle6:before {
    animation-delay: -0.4s;
  }
  #circles .circle7:before {
    animation-delay: -0.3s;
  }
  #circles .circle8:before {
    animation-delay: -0.2s;
  }
  #circles .circle9:before {
    animation-delay: -0.1s;
  }

  @keyframes lebe {
     0%, 50% {opacity: 0}
     51% {opacity: 1}
     100%{opacity: 0}
  }

/* ------------------- MUI TABLES ------------- */

.MuiDataGrid-main, .MuiDataGrid-footerContainer {
    background-color: #fff;;
}

.menu a {
    color: var(--highlight);
    border-bottom: 1px solid var(--lightgrey);
    padding: 6px 10px;
    text-decoration: none;
    /*text-transform: uppercase;*/
}

.menu a i {
    margin-right: 10px; 
}

.submenu {
    margin: 10px 0;
    padding-left: 20px;  
}

.submenu a {
    text-transform: none;
}

.menu a.active, .menu a:hover {
    color: var(--tertiary);
    cursor: pointer;
}

.menu a:hover {
    background-color: #efefef;
}

.menu h3 {
    transition: unset;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 20px;
    margin-bottom: 5px;
    letter-spacing: 2px;
}

/*.MuiFormControl-root {
    margin: 0 0 0px 0 !important;
}*/

.MuiFormControl-root > label, .MuiFormControl-root > div{
    background-color: #fff;
}

form > div:first-child{
    border-top: 1px solid var(--lightgrey);
    /*border-radius: 10px;*/
    padding-top: 50px;
}

/* --------------------------- FORM ELEMENTS  ----------------------*/

form.form-height-100 > div:first-child {
    height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}

form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not(.MuiInputBase-input),
select{
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    padding: 9px 9px;
}

.productvariation select {
    max-width: 500px;
}

select {
    background-color: #fff;
    max-width: 560px;
    width: 100%;
}

.privacy {
     margin-top: 20px;   
}
.privacy span, .newsletter span{
    max-width: 180px;
    word-break: break-word;
    text-align: left;
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: 0;
}

.privacy span.errortext {
    max-width: initial;
}

.newsletter label {
    justify-content: flex-end;
}

.newsletterinput.newsletter {
    width: 100%;
    max-width: 260px;
}
.newsletterinput.newsletter label {
    justify-content: flex-end;
}

form .offertable-container .headdata input:not([type="checkbox"]:not([type="radio"])) {
    border-radius: none;
    border: none;
    margin-bottom: 0px;
    padding: 0;
}

.discountExplanation {
    color: var(--highlight);
    margin: 20px 0;
    text-align: center;
}

.form-headline {
    background-color: #f5f5f5;
    margin-bottom: 20px;
    padding: 5px 5px ;
    max-width: 790px;
}

/*.form-headline:after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--highlight);
    height: 1px;
    margin-top: 10px;
    width: 60%;
}*/


form label span {
    display: inline-block;
    font-weight: bold;
    flex-shrink: 0;
    font-size: 15px;
    width: 220px;
    margin-right: 10px;
    margin-bottom: 20px;
    word-break: break-all;
}

form .labelcontainer {
    display: flex;
}

.productvariation .labelcontainer.type-checkbox > .inputcontainer  {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(4, 250px);
    display: grid;
}

@media screen and (min-width: 1600px) {
    .productvariation .labelcontainer.type-checkbox > .inputcontainer  {
        grid-template-columns: repeat(5, 250px);
    }
}

@media screen and (max-width: 1599px) {
    .productvariation .labelcontainer.type-checkbox > .inputcontainer  {
        grid-template-columns: repeat(4, auto);
    }
}

.labelcontainer > .inputcontainer > div {
    margin-right: 40px;
}

[type="checkbox"]:disabled,
[type="checkbox"]:disabled + label {
    opacity: 0.4;
}

[type="checkbox"], [type="radio"] {
    position: relative;
    left: 30px;
    top: 0px;
    z-index: 0;
    -webkit-appearance: none;
  }
[type="checkbox"] + label, [type="radio"] + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-family: sans-serif;
    line-height: 1.3;
    margin-bottom: 10px;
    padding-left: 55px;
    position: relative;
    margin-top: -20px;
  }
[type="checkbox"] + label:before, [type="radio"] + label:before {
    width: 40px;
    height: 20px;
    border-radius: 20px;
    border: 2px solid #ddd;
    background-color: #EEE;
    content: "";
    margin-right: 15px;
    transition: background-color 0.5s linear;
    z-index: 5;
    position: absolute;
    left: 0px;
  }
.importantCheckbox[type="checkbox"] + label:before {
    background-color: rgb(165, 90, 90);
}

[type="checkbox"] + label:after, [type="radio"] + label:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    content: "";
    transition: margin 0.1s linear;
    box-shadow: 0px 0px 5px #aaa;
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 10;
  }
  [type="checkbox"]:checked + label:before, [type="radio"]:checked + label:before  {
    background-color: var(--highlight);
  }
  [type="checkbox"]:checked + label:after, [type="radio"]:checked + label:after {
    margin: 0 0 0 20px;
  }




.texteditor .tiptap  {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    min-height: 125px;
    padding: 0px 10px;
}

.texteditor > button {
    display: inline-block;
    margin: 5px 5px 5px 0;
    padding: 4px 15px;
}

button > i {
    margin: 0 5px;
}

.inputcontainer {
    display: flex;
}

.labelcontainer.type-file .inputcontainer {
    align-items: center;
    margin-bottom: 40px;
}
.labelcontainer.type-file .inputcontainer > :first-child{
    width:  200px ;
    gap: 10px
}
.labelcontainer.type-file .inputcontainer > input{
    margin-bottom: 0;
    margin-right: 20px;
}
.labelcontainer.type-file .inputcontainer > button{
    margin-top: 0;
}
.labelcontainer.type-file .inputcontainer > div {
    display: flex;
    
}

.labelcontainer.type-file img {
    height: 100px;
    margin-bottom: 20px;
    width: auto;
}


input[type='submit'], button, .button {
    background-color: var(--lightgrey);
    /*border: 2px solid var(--primary);*/
    border: none;
    border-radius: none;
    color: var(--primary);
    display: table;
    font-family: var(--fontFamily);
    font-size: 13px;
    margin-top: 20px;
    padding: 10px 20px;
    position: relative;
    width: initial;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    transition: all 300ms;
}

.buttoncontainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button i, button i {
    margin: 0 5px;
}

form input[readonly] {
    border-color: #fff;
    opacity: 0.8;
}

button i {
    margin-right: 8px;
    margin-left: 8px;
}

.texteditor i {
    margin-right: 0px;
}

.secondbutton {
    background-color: var(--tertiary);
    border-color: var(--tertiary);
    color: #fff;
}

input[type='submit']:hover, button:hover, .button:hover, .button.fav {
    background-color: var(--secondary);
    border-color: var(--secondary);
    cursor: pointer;
    transition: all 300ms;
}

.tabs > button {
    background-color: #efefef;
    border: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px 20px;
    margin-right: 5px;
}
.tabs > button:hover {
    cursor: pointer;
}
.tabs > button.active, .tabs > button:hover  {
    background-color: var(--lightgrey);
}
.tabs {
    padding-left: 10px;
    display: flex
}

.css-1yqais2 {
    padding: 4px;
}

/*.offertable-container {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    padding-top: 20px !important;
    gap: 20px;
}

.offertable-container .MuiDataGrid-main.css-3eek4p-MuiDataGrid-main {
    height: calc(100vh - 600px);
}

.offertable-container .MuiDataGrid-toolbarContainer, 
.offertable-container .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer {
    justify-content: space-between;
}*/

.offertable-container .css-i9gxme {
    display: none;
}

.timeline {
    display: flex;
    font-size: 12px;
    justify-content: center;
    margin: 30px auto 30px;
}
.timeline > div {
    margin-right: 30px;
    
    position: relative;
    
}

.timeline > div i {
    background-color: var(--light);
    display: inline-block;
    border-radius: 50%;
    color: #fff;
    font-style: normal;
    height: 20px;
    margin-right: 10px;
    padding: 2px 6px 3px; 
    width: 20px;
}

.timeline > div:not(.active) {
    opacity: 0.7;
}

.timeline > div.active i {
    background-color: var(--highlight);
}

#productinoffer {
    color: var(--highlight);
    display: block;
    position: absolute;
    top: 20px;
    right: 0;
    font-size:0.7rem;
}

.newperformance {
    margin-top: 30px;
    border-top: 1px solid var(--highlight);
}

/* --------------------------- PERFORMANCE ELEMENTS  ----------------------*/


.performance_details.active {
    display: block;
    transition: all 500ms;
}

.performance_details {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    grid-template-columns: auto auto;
}

.performance_details > div {
    width: 33%;;
}

@media screen and ( max-width: 1760px) {
    .performance_details > div {
        width: 50%;
    }
}

@media screen and ( max-width: 1400px) {
    .performance_details > div {
        width: 100%;
    }

    form .performance_details label span {
        width: 140px;
    }
}

@media screen and ( max-width: 1200px) {
    .performance_details > div {
        width: 100%;
    }
    .performance_details {
        grid-template-columns: auto;
    }

    form .performance_details label span {
        width: 180px;
    }
}

.performance_details .detail {
    width: 100%;
}

.performance_preview {
    background-color: #efefef;
    border-radius: 10px;
    margin-bottom: 10px;
    min-height: 41px;
    padding: 10px 10px 5px;
    display: flex;
    justify-content: space-between;
}

.performance_preview > div {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 60px);
}

.performance_preview > div i {
    padding: 0 6px;
}
.performance_preview > div i:hover {
    color: var(--highlight);
    cursor: pointer;
}

.performance_preview span {
    background-color: #ccc;
    display: inline-block;
    font-size: 12px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom:5px;
    padding: 5px 10px;
}

.form-part:not(.active){
    display: none
}

#form-part-1:not(.active){
    align-items: center;
    display: flex;
    margin-bottom: 50px;
    opacity: 0.8;
}

#form-part-1:not(.active) > div:first-child input{
    min-width: 40px;
    width: 40px;
    pointer-events:none;

}
#form-part-1:not(.active) > div:nth-child(2) input{
    min-width: 100%;
    width: 150px;
    pointer-events:none;

}
#form-part-1:not(.active) > div:nth-child(3) select{
    min-width: 100%;
    width: 150px;
    -webkit-appearance: none;
    -moz-appearance: none;
    pointer-events:none;

}

#form-part-1:not(.active) > div input:focus,
#form-part-1:not(.active) > div input:focus-visible,
#form-part-1:not(.active) > div select:focus-visible {
    outline: none;
}


#form-part-1.active .inputcontainer,
#form-part-1.active .MuiFormControl-root,
#form-part-1.active .MuiFormControl-root > div {
    display: block;
    width: 100%;
    max-width: 500px;
}
#form-part-1.active input {
    width: 100%;
}

#form-part-1.active .labelcontainer > .inputcontainer > div{
    margin-right: 0;
}

#form-part-1:not(.active) label span {
    margin-bottom: 0;
    opacity: 0.5;
    width: initial
}

#form-part-1:not(.active) input,
#form-part-1:not(.active) select {
    border: none;
    font-size: 15px;
    margin-bottom: 0;
    padding: 0px;
    min-width: 80px;
    width: 80px;
}

#form-part-1:not(.active) select {
    width: 150px;
}

#form-part-1:not(.active) .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
    border: none;
}

#form-part-1:not(.active) .MuiFormControl-root > label, #form-part-1:not(.active) .MuiFormControl-root > div {
    padding: 0;
    width: 180px;
}

#form-part-1:not(.active) .css-op7nof-MuiFormControl-root {
    min-width: 200px;
    margin-bottom: 0 !important;
}


#form-part-1:not(.active) label {
    align-items: center;
}

#form-part-1:not(.active) .richeditor{
    display: none;
}

#form-part-2 > div:nth-child(odd),
#form-part-3 > div:nth-child(odd) {
    background-color: #f2f2f2;
}

#form-part-2 > div, #form-part-3 > div {
    padding: 10px 10px 0;
}

.texteditor {
    width: 100%;
    max-width: 560px;
}

section.loginsection {
    height: initial;
}

#login {
    max-width: 300px;
    margin: 10vh auto;
    text-align: center;
}

#login a {
    margin: 5px 0;
}

#login.register {
    max-width: 460px;
    margin: 50px auto 10vh;
}

#login h1 {
    margin: 20px 0 40px;
}

#login.register form label span {
    width: 100%;
}
#login.register form label input{
    width: 260px;
    max-width: 100%;
}
#login.register form .registrationinputs label input{
    width: initial;
}

#login.register form .newsletterinput label {
    flex-direction: row-reverse;
}

#login.register form .newsletterinput label input,
#login.register form .newsletterinput label,
#login.register form .newsletterinput label span {
    width: initial;
}

#login.register form .newsletterinput label span {
    margin-top: 10px;
}
#login.register form .newsletterinput .checkboxcontainer {
    margin-right: 0;
}

#login.register form {
    padding-bottom: 50px;
}

#login.register .form-headline:after {
    width: 100%;
}

#login.register.page-1 form label span {
    width: 220px;
}
#login.register.page-1 .labelcontainer > .inputcontainer > div {
    margin-right: 0px;
}

#login img {
    width: 150px;
}

.registrationinputs {
    text-align: left;
    margin-bottom: 30px;
}

#login form {
    border: 1px solid var(--lightgrey);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 10px 10px 30px;
}

.errortext, .successtext {
    color: #ff0000;
    display: block;
    font-weight: normal;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}
.successtext {
    color: #5cb047;
}

#table {
    width: calc(100vw - (var(--sidemenuwidth) + 80)); 
    overflow-x: auto;
    margin-top: 50px;
    height: calc(100vh - 300px);
}

.offerdetail #table {
    height: calc(100vh - 380px);
    margin-top: 20px;
}

.combinationstable #table {
    height: calc(100vh - 430px);
}

table {
    border-collapse: collapse;
    border-color: var(--light);
    width: 100%;
    text-align: left;
}

table tr td, table tr th {
    border-bottom: 1px solid var(--light);
    border-collapse: collapse;
    padding: 15px 0;
}

table .offerlist td > div > span:first-child {
    font-weight: bold;
}

.offerdetailform {
    overflow: auto;
    height: calc(100vh - 300px);
}

.offertable {
    font-size: 0.9rem;
    margin: 0 auto;
    max-width: 90%;
    overflow: auto;
}

.offertable .fa-trash:hover {
    cursor: pointer;
    color: var(--secondary)
}

.offertable input {
    margin-bottom: 0 !important;
    width: 50px;
}

.offerdata .texteditor {
    margin-right: 40px;
    max-width: 100%;
}



.flex {
    display: flex;
    gap: 10px;
}

.offerdata > div {
    max-width: 90%;
    margin-left:  auto;
    margin-right:  auto;

}

.offerdata h3 {
    text-align: center;    
}

.uploadprices {
    margin-top: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/*--------------- DATAGRID -------------- */

.MuiDataGrid-main {
    height: calc(100vh - 450px);
    /*width: calc(100% - 400px);*/
    overflow-x: auto;
}

.MuiFormControl-root[aria-label="Search"] {
    margin: 0 !important
}

.MuiDataGrid-toolbarContainer, .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer {
    background-color: var(--lightgrey);
    color: #000;
    padding: 5px 20px;
}

.MuiDataGrid-toolbarContainer button, .MuiDataGrid-toolbarContainer.css-128fb87-MuiDataGrid-toolbarContainer button {
    color: #000;
}

.MuiFormControl-root > label, .MuiFormControl-root > div {
    background-color: #fff;
    padding: 2px 10px;
    border-radius: 8px;
}

.MuiFormControl-root.MuiTextField-root.MuiDataGrid-toolbarQuickFilter {
    padding-bottom: 0;
}


#preview {
    font-size: 14px;
    max-width: 21cm;
    min-height: 29cm;
    box-shadow:  1px 1px 10px #777;
    padding: 1cm;
    margin-bottom: 50px;
}

#preview .clientdata {
    margin-bottom: 20px;
}

#preview .clientdata.kerion {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
#preview .clientdata.kerion img {
    width: 150px;
}

#preview .clientdata.kerion div:last-child {
    text-align: right;
}


#preview .offertable{
    max-width: 100%;
}

#preview {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.offerpositionfooter.preview {
    border-top: 1px solid var(--secondary);
    font-size: 0.8rem;
    padding-top: 10px;
    margin-top: 50px;
}
.offerpositionfooter.preview  p {
    margin: 0;
}

.offerdata.preview {
    padding-bottom: 80px;
}

.offerdata.preview tr.net td, .offerdata.preview tr.net,
.offerdata.preview tr:last-child td, .offerdata.preview tr:last-child {
    border-bottom: 0;
}

.offerdata.preview tr.net td {
    padding-bottom: 0px;
}
.offerdata.preview tr.vat td {
    padding-top: 5px;
}

.offerdata.preview th:nth-last-of-type(2),
.offerdata.preview td:nth-last-of-type(2) {
    text-align: center;
}

.offerdata.preview th:last-of-type,
.offerdata.preview td:last-of-type {
    padding-right: 0;
    text-align: right;
}

.status {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 10px;
}

.status > div {
    background-color: var(--ready);
    border-radius: 100%;
    height: 10px;
    width: 10px;
}

.status > div.new {
    background-color: var(--error);
}
.status > div.done, .status > .ordered-1 {
    background-color: var(--success);
}
.status > .ordered-0 {
    background-color: var(--ready);
}


.offerstate {
    align-items: center;
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 30px;
}

.offerstate .status > div {
    height: 15px;
    width: 15px;
}

.offerstate h4 {
    margin-top: 0
}

.offerstate label {
    margin-bottom: 0;
}

/* ----------------- OFFER ------------ */

.product_category {
    border: 1px solid #000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
}

.product_category .product_title {
    background-color: #000;
    color: #fff;
    text-align: center;
    margin-top: 0;
    padding: 5px;
    transition: all 300ms;
}

.product_category img {
    object-fit: cover;
    object-position: center;
    height: 200px;
    max-height: 200px;
}



.product_category span {
    background-color: rgba(0, 0, 0, 0.5);
    height: calc((33vw - 40px) / 16* 9);
    max-height: 250px;
    position: absolute;
    right: -100%;
    width: 100%;
    transition: all 300ms;
}

@media screen and (min-height: 1200px) {
    .product_category img {
        height: 300px;
        max-height: 300px;
    }

    body .product_category span{
        max-height: 350px;
    }
}
.product_category:hover span {
    cursor: pointer;
    right: 0;
}

#products_table_container {
    max-height: calc(100vh - 420px);
    overflow: auto;
}

thead tr:first-child, tr:last-child {
    border-bottom: 1px solid #000;
}

.table_headline {
    height: 40px;
    position: relative;
}

.table_headline th {
    font-weight: bold;
    padding: 7px 36px 7px 10px;
}

#products_table_container tr:not(.notInSearch):not(.notlightservice):nth-child(2n) {
    background-color: #e8e8e8;
}

#products_table_container td, #products_table_container th {
    font-size: 0.9rem;
    position: relative;
    padding: 15px 9px;
    text-align: left;
    white-space: nowrap;
}


#products_table_container td:first-child {
    padding: 0;
}

#products_table_container td:first-child .fas{
    padding: 15px 10px;
    display: block;
}

#products_table_container td:first-child .fas:hover{
    cursor: pointer;
}

#products_table_container .active .fas {
    color: var(--secondary);
}

.product_back_container {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
}

.product_back_container h4 {
    font-size: 1rem;
    margin-top: 0;
    text-transform: uppercase;
}

.product_back_container button, .product_back_container i {
    background-color: transparent;
    margin-top: 0;
    padding: 0;
    font-size: 1rem;
}

.product_desc, .category_data {
    line-height: 1.2;
    font-size: 0.8rem;
}

.product_back_container button:hover, .product_back_container i:hover {
    color: var(--highlight);
    cursor: pointer;
}

.category_data strong {
    display: inline-block;
    width: 147px;
}

.filter_buttons label:before,
.filter_buttons label:after {
    display: none;
}

.filter_buttons input:checked {
    color: var(--secondary);
}

.filter_buttons input[type='checkbox'] {
    display: none;
}

.filter_buttons input[type='checkbox'] + label {
    border: 1px solid var(--primary);
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;
    line-height: 1;
    padding: 6px 12px;
}

.filter_buttons input[type='checkbox'] + label:hover, 
.filter_buttons input[type='checkbox']:checked + label {
    border: 1px solid var(--secondary);
    background-color: var(--secondary);
}

.filter_buttons > div {
    display: flex;
    gap: 10px;
}

.filter_buttons h5 {
    margin: 10px 0 15px 0;
    text-transform: uppercase;
}

.productsearch {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 15px;
    margin-top: -60px;
}

.productsearch input {
    margin-bottom: 0 !important;
}

.category_data  > div{
    padding-bottom: 5px;
}

.filter_slider > div {
    margin: 0 20px;
    padding-top: 20px;
}

.highlight {
    color: var(--secondary)
}

#products_table_container thead tr {
    background-color: #fff;;
    position: sticky;
    top: 0;
    z-index: 100;
}

.stickysection {
    position: sticky;
    top: 0;
}

.active .fas {
    color: var(--highlight);
}

.offerdata div, .offerdata input, .offerdata textarea {
    width: 100%;
}

.offerpositions.preview h3 {
    margin-top: 50px;
}

.offerpositions.preview {
    padding: 0 20px;
}

.offerpositions.preview table {
    font-size: 0.7rem;
}

.errorcontainer {
    margin: 100px auto;
    text-align: center;
}

.errorcontainer .buttoncontainer {
    display: flex;
    gap: 10px;
    justify-content: center;
}


.errorview {
    text-align: center;
}

.userform .type-text input {
    max-width: 100%;
    width: 560px;
}

.mb-1 {
    margin-bottom: 1rem;
}

button[disabled] {
    cursor: not-allowed
}


#sidemenuclose {
    background-color: #efefef;
    display: none;
    position: absolute;
    right: -40px;
    padding: 10px 15px;
}

#sidemenuclose:hover {
    cursor: pointer;
    color: var(--highlight);
}

#sidemenu.open #sidemenuclose .fa-chevron-right{
    display: none;
}
#sidemenu:not(.open) #sidemenuclose .fa-chevron-left{
    display: none;
}

#sidemenu:not(.open) > div{
    left: calc(0px - var(--sidemenuwidth));
}

#sidemenu {
    overflow-y: auto
}

@media screen and ( max-width: 960px) {

    #sidemenuclose {
        display: block;
    }
    .main {
        display: block
    }

    section {
        padding: 10px;
    }

    form label span {
        width: 150px;
    }

    .userform .type-text input {
        width: 100%;
    }
}